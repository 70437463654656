* {
    font-family: 'Nunito', sans-serif !important;
}

a {
    text-decoration: none !important;
}

:root {
    --brand-color: #ed8a4c;
    --secondary-color: #b0b0b0;
    --border-color: #435167;
    --button-color: #1ab449;
    --highlight-color: #e5c03e;
    --background-color: #212B36;
    --dark-color: #1e242f;
}

.line-clamp-1{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.line-clamp-2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.line-clamp-3{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.line-clamp-4{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.line-clamp-5{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.w-25 {
    width: 25% !important;
}

.w-fit {
    width: fit-content;
    width: -moz-fit-content;
}

/*Background Color*/
.bg-color-brand {
    background-color: var(--brand-color) !important;
}

.bg-color-secondary {
    background-color: var(--secondary-color) !important;
}

.bg-color-border {
    background-color: var(--border-color) !important;
}

.bg-color-button {
    background-color: var(--button-color) !important;
}

.bg-color-highlight {
    background-color: var(--highlight-color) !important;
}

.bg-color-background {
    background-color: var(--background-color) !important;
}

.bg-color-dark {
    background-color: var(--dark-color) !important;
}

.bg-gradient {
    background: linear-gradient(180deg,#344053,#262f3d) !important;
}



/*Color*/
.color-brand {
    color: var(--brand-color) !important;
}

.color-secondary {
    color: var(--secondary-color);
}

.color-border {
    color: var(--border-color) !important;
}

.color-button {
    color: var(--button-color) !important;
}

.color-highlight {
    color: var(--highlight-color) !important;
}

.color-background {
    color: var(--background-color) !important;
}

.color-dark {
    color: var(--dark-color) !important;
}



/*Type Face*/
.txt-sub-content {
    font-size: 0.92rem;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semibold {
    font-weight: 600 !important;
}

.fw-extrabold {
    font-weight: 800 !important;
}

.fw-heavy {
    font-weight: 900 !important;
}



/*Custom Style*/
.rarity-text {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
}

.border-card-common {
    background: linear-gradient(var(--background-color), 75%, #cccccc) !important;
}

.border-card-rare {
    background: linear-gradient(var(--background-color), 80%, #00ff33) !important;
}

.border-card-epic {
    background: linear-gradient(var(--background-color), 75%, #cc33ff) !important;
}

.border-card-legend {
    background: linear-gradient(var(--background-color), 80%, #ffff00) !important;
}

.border-card-mythic {
    background: linear-gradient(var(--background-color), 75%, #ff9900) !important;
}

.border-card-meta {
    background: linear-gradient(var(--background-color), 65%, #ff3333) !important;
}

.hero-card-wrap {
    cursor: pointer;
    padding: 2px 2px 3px 2px;
    text-decoration: none !important;
    transition: all 0.25s ease !important;
    -webkit-transition: all 0.25s ease !important;
    -moz-transition: all 0.25s ease !important;
    -o-transition: all 0.25s ease !important;
    -ms-transition: all 0.25s ease !important;
}

.hero-card-wrap .hero-card {
    background-color: var(--background-color);
    border-radius: 14px !important;
    padding: 10px 15px;
    transition: all 0.25s ease !important;
    -webkit-transition: all 0.25s ease !important;
    -moz-transition: all 0.25s ease !important;
    -o-transition: all 0.25s ease !important;
    -ms-transition: all 0.25s ease !important;
}

.hero-card-wrap:hover {
    transform: scale(1.01, 1.01);
}

.hero-card-wrap:hover > hero-card {
    background-color: #242f3a !important;
}

.hero-card-wrap .thumb-wrap {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    min-height: 172px;
}

.hero-card-wrap .hero-card-thumb {
    width: auto !important;
    max-height: 172px;
}

.bomb-card-wrap {
    padding: 2px 2px 3px 2px;
    border-radius: 16px !important;
    position: relative;
    cursor: pointer;
    text-decoration: none !important;
    transition: all 0.25s ease !important;
    -webkit-transition: all 0.25s ease !important;
    -moz-transition: all 0.25s ease !important;
    -o-transition: all 0.25s ease !important;
    -ms-transition: all 0.25s ease !important;
}

.bomb-card-wrap:hover {
    transform: scale(1.01, 1.01);
}

.bomb-card-wrap:hover > .bomb-card {
    background-color: #242f3a !important;
}

.bomb-card-wrap .bomb-card {
    background-color: var(--background-color);
    border-radius: 14px !important;
    padding: 10px 15px;
    transition: all 0.25s ease !important;
    -webkit-transition: all 0.25s ease !important;
    -moz-transition: all 0.25s ease !important;
    -o-transition: all 0.25s ease !important;
    -ms-transition: all 0.25s ease !important;
}

.bomb-card-wrap .bomb-thumb {
    width: 100%;
    height: auto;
    max-width: 170px;
    z-index: 2;
}

.bomb-card-wrap .bomb-container {
    width: 210px;
    min-width: 210px;
    height: 210px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hexagon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: auto;
    width: 170px;
    height: 98.15px;
    background-color: #202933;
    margin: 49.07px 0;
    border-left: 1px solid rgba(145, 158, 171, 0.15);
    border-right: 1px solid rgba(145, 158, 171, 0.15);
    z-index: 0;
}

.hexagon svg {
    z-index: 2;
}

.hexagon:before,
.hexagon:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 120.21px;
    height: 120.21px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 23.8959px;
}

.hexagon:before {
    top: -60.1041px;
    border-top: 1px solid rgba(145, 158, 171, 0.15);
    border-right: 1px solid rgba(145, 158, 171, 0.15);
}

.hexagon:after {
    bottom: -60.1041px;
    border-bottom: 1px solid rgba(145, 158, 171, 0.15);
    border-left: 1px solid rgba(145, 158, 171, 0.15);
}

.bomb-card-wrap .bomb-skill {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-color);
    box-shadow: inset 0px 0px 9px rgba(0,0,0,0.45);
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    border: 1px solid rgba(145, 158, 171, 0.24);
    z-index: 1;
}

.bomb-card-wrap .bomb-skill-1 {
    top: 0;
    left: 84px;
}

.bomb-card-wrap .bomb-skill-2 {
    top: 42px;
    left: 0;
}

.bomb-card-wrap .bomb-skill-3 {
    top: 126px;
    left: 0;
}

.bomb-card-wrap .bomb-skill-4 {
    top: 168px;
    left: 84px;
}

.bomb-card-wrap .bomb-skill-5 {
    top: 126px;
    left: 168px;
}

.bomb-card-wrap .bomb-skill-6 {
    top: 42px;
    left: 168px;
}

.hero-detail-thumb {
    width: auto;
    max-height: 310px;
}

.box-content {
    width: 100%;
    max-width: 600px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}